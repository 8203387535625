$notice-color: #f99547;
$review-color: #10c9b2;
$assist-color: #9c7be2;
$approval-color: #f1725e;
$primary-color: #1890ff;

:root {
  --notice-color: #f99547;
  --review-color: #10c9b2;
  --assist-color: #9c7be2;
  --approval-color: #f1725e;
  --primary-color: #1890ff;
}
