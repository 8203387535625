@import "./themes";

// mixins
@mixin box-shadow {
  box-shadow: 0px 0px 6px 0px #cccccc;
}

.primary-color {
  color: $primary-color;

  &:hover {
    color: #40a9ff;
  }
}

.under-mark {
  padding-bottom: 0.1em;
  border-bottom: 3px solid $review-color;
}

// 基本样式设置
body {
  height: 100vh;
  background: #f0f2f5;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.cursor-pointer {
  cursor: pointer;
}

.white {
  background: #fff;
}

.white-color {
  color: #fff;
}

.inline-block {
  display: inline-block;
}

.overflow-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// antd sider 样式覆盖
.ant-layout-sider.ant-layout-sider-dark.big-sider {
  overflow: auto;
  box-shadow: 0px 0px 6px 0px rgba(204, 204, 204, 1);
  background: $primary-color;
}

// antd menu 样式覆盖
.ant-menu-dark {
  background: $primary-color;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: $primary-color;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #05d6ce;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: $primary-color;
  box-shadow: 0 2px 8px rgba(6, 73, 108, 0.45) inset;
}

// antd-pro page-header 样式覆盖
.antd-pro-page-header-breadcrumb {
  text-align: left;
}

.antd-pro-page-header-title,
.antd-pro-page-header-content {
  text-align: left;
}

.antd-pro-page-header-content {
  color: #999;
}

// 返回按钮样式
.goback {
  padding: 6px 12px;
  color: #5f6368;
  cursor: pointer;
  border-radius: 20px;
  transition: 0.3s;
  i {
    padding-right: 8px;
  }
  &:hover {
    background: #eee;
  }
}

// 自定义滚动条~hover显示
.beautiful-scroll-bar-hover {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #c6d0da;
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparent;
    &:hover {
      background: #b5bec7;
    }
    &:active {
      background: #9ea1a5;
    }
  }
}

// 自定义滚动条~一直显示
.beautiful-scroll-bar {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c6d0da;
    &:hover {
      background: #b5bec7;
    }
    &:active {
      background: #9ea1a5;
    }
  }
}

// 多用于展示form表单编辑后的lebel文本
.text-label {
  color: #858e99;
}

/* 拖拽样式start */

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  height: 36px; // opacity: 0.9;
  // filter: alpha(opacity=80);
  cursor: move;
  cursor: grabbing;
  box-shadow: 0px 4px 9px 0px #ccc;
  border: 1px solid #ddd;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 3px;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  filter: alpha(opacity=20);
}

/* 拖拽样式end */

// antd spin标签高度撑满容器class
.spin-full-wrapper {
  height: 100%;
  .ant-spin-container {
    height: 100%;
  }
}

/*打印样式隐藏内容*/

@media print {
  @page {
    size: auto;
    margin: 0mm;
  }
  .print {
    display: block;
  }
  .nprint {
    display: none;
  }
}
