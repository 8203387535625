.businessCard {
  .ant-modal-content {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  }
  .ant-modal-header {
    height: 120px;
    background: #acbdce;
  }
  .ant-modal-body {
    padding: 0;
    .carte {
      padding: 24px;
      height: 340px;
    }
  }
}