@import '../index.scss';
.header {
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  right: 0;
  width: 100%;
  background: #fff;
  height: 61px;
  color: #333;
  box-shadow: 0px 0px 6px 0px rgba(204, 204, 204, 1);
  background: url('../assets/images/header/tb.png') center/cover;
  .ant-input {
    height: 28px;
    border-radius: 20px;
    background-color: transparent;
    border-color: rgba(255, 255, 255, .5);
  }
  .ant-input:hover,
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #fff;
  }
  .ant-input-affix-wrapper .ant-input-prefix {
    color: rgba(255, 255, 255, .5);
    transition: .3s;
  }
  .ant-input-affix-wrapper:hover .ant-input-prefix {
    color: #fff;
  }
  .focus .ant-input {
    background-color: #fff;
  }
  .ant-menu {
    background: transparent;
  }
  .ant-menu-horizontal>.ant-menu-item-selected a {
    color: #fff;
    font-weight: bolder;
  }
  .header-nav {
    a {
      font-size: 17px;
      color: #eee;
    }
  }
  .ant-badge-count {
    top: -6px;
    height: 16px;
    line-height: 16px;
    min-width: 16px;
    box-shadow: 0 0 0 2px #7281f9;
  }
  .ant-scroll-number-only,
  .ant-scroll-number-only>p {
    height: 16px;
  }
}

.ant-layout-header {
  padding: 0;
  line-height: 60px;
}

.ant-menu-horizontal {
  border: none;
  >.ant-menu-item {
    border: none;
  }
  >.ant-menu-item-selected {
    color: #fff;
    border: none;
  }
  >.ant-menu-item>a {
    color: #f5f5f5;
  }
  >.ant-menu-item:hover,
  .ant-menu-horizontal>.ant-menu-item-active {
    color: #fff;
    border: none;
  }
  >.ant-menu-item:hover>a,
  .ant-menu-horizontal>.ant-menu-item-selected>a {
    color: #fff;
    border: none;
  }
}

.searchTab {
  position: absolute;
  background: rgb(255, 255, 255);
  width: 100%;
  left: 0;
  height: 520px;
  padding: 0 10px;
  box-shadow: 3px 3px 9px 0px #ccc;
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 9px 0 0;
    padding: 12px;
  }
}

